<template>
	<article>
		<hero-image
			:alt="hero.alt"
			:heading="hero.heading"
			:img-small="hero.imgSmall"
			:img-large="hero.imgLarge"
		/>
		<v-container class="py-0">
			<v-row no-gutters align="center">
				<v-col cols="12">
					<breadcrumbs :crumbs="crumbs" />
				</v-col>
			</v-row>
		</v-container>
		<v-container class="content" tag="section">
			<v-row>
				<v-col cols="12">
					<h4>Introduction</h4>
					<p>
						These terms and conditions govern your use of this website; by using this website, you accept these terms and conditions in full. If you
						disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.
					</p>
					<h4>License to Use Website</h4>
					<p>
						Unless otherwise stated, Sydney Kids Neuropsychology and/or its licensor's own the intellectual property rights in the website and
						material on the website. Subject to the license below, all these intellectual property rights are reserved.
					</p>
					<p>
						You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the
						restrictions set out below and elsewhere in these terms and conditions.
					</p>
					<p>You must not:</p>
					<ul>
						<li>republish material from this website (including republication on another website);</li>
						<li>sell, rent or sub-license material from the website;</li>
						<li>show any material from the website in public;</li>
						<li>reproduce, duplicate, copy or otherwise exploit material on this website for a commercial purpose;</li>
						<li>edit or otherwise modify any material on the website; or</li>
						<li>redistribute material from this website except for content specifically and expressly made available for redistribution.</li>
					</ul>
					<h4>User Content</h4>
					<p>
						Sydney Kids Neuropsychology reserves the right to edit or remove any material submitted to this website, or stored on Sydney Kids
						Neuropsychology's servers, or hosted or published upon this website.
					</p>
					<p>Images and media used on this website are for illustrative purposes only.</p>
					<p>
						Any prices or monetary values mentioned on this website are subject to change without prior notice. Contact Sydney Kids Neuropsychology
						by
						<router-link to="/contact">
							email
						</router-link>for further pricing information.
					</p>
					<h4>Registrations &amp; Authorisations</h4>
					<p>Sydney Kids Neuropsychology's ABN number is: 33529485595</p>
					<p>
						You can contact Sydney Kids Neuropsychology by
						<router-link to="/contact">
							email
						</router-link>
					</p>
				</v-col>
			</v-row>
		</v-container>
	</article>
</template>

<script>
import { _MxAppViewClass } from '@/mixins/global';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import HeroImage from '@/components/image/HeroImage';
export default {
	name: 'Terms',
	components: {
		Breadcrumbs,
		HeroImage
	},
	metaInfo: {
		title: 'Terms & Conditions',
		meta: [
			{
				name: 'description',
				content:
					'Website terms and conditions for Sydney Kids Neuropsychology.'
			},
			{
				name: 'robots',
				content: 'noindex'
			}
		]
	},
	mixins: [_MxAppViewClass],
	data() {
		return {
			crumbs: [
				{
					text: 'home',
					disabled: false,
					to: '/'
				},
				{
					text: this.$route.name,
					disabled: true
				}
			],
			hero: {
				alt: this.$route.name,
				heading: this.$route.name,
				imgSmall: 'hero-04-small',
				imgLarge: 'hero-04-large'
			}
		};
	}
};
</script>
